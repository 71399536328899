import React, { Fragment, useState, useEffect } from 'react';
import { Select, InputNumber, Tooltip, Switch } from 'antd';

function RecoveryWeight(props) {
  const { onChange, value, disabled } = props;

  function handleChange(key, changedValue) {
    if (onChange) {
      onChange({
        ...value,
        [key]: changedValue,
      });
    }
  }

  return (
    <Fragment>
      <InputNumber
        className="md-input"
        min={0}
        disabled={disabled}
        value={value.recoveryWeightAdd}
        onChange={(value) => {
          handleChange('recoveryWeightAdd', value);
        }}
      />
      <div className="form-row">
        <Switch size="small" checked={value.recoveryWeight} onChange={(value) => handleChange('recoveryWeight', value)} disabled={disabled} />
        动态权重
        <Tooltip title="保证设置了相同“事件”的调研回收量相近">
          <i className="iconfont icon-bangzhu" />
        </Tooltip>
      </div>
    </Fragment>
  );
}

export default RecoveryWeight;
