import React, { Fragment, useState, useEffect } from 'react';
import { Select, InputNumber, Input, Switch } from 'antd';
import { weekOption, numberFormulaList } from '../../../../config';
import util from '../../../../util';
import api from '../../../../api';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';

const { Option } = Select;

function Collection(props) {
  const { onChange, value, disabled } = props;

  function handleChange(key, changedValue) {
    if (onChange) {
      const data = {
        ...value,
        [key]: changedValue,
      };
      onChange(data);
    }
  }

  return (
    <div className="collection-form-item">
      <div className="form-row">
        <Select className="md-select" onChange={(value) => handleChange('operator', value)} value={value.operator} disabled={disabled}>
          {numberFormulaList.slice(0, 6).map((item) => {
            return <Option key={item.value}>{item.label}</Option>;
          })}
        </Select>
        <InputNumber className="sm-input" min={1} onChange={(value) => handleChange('value', value)} value={value.value} disabled={disabled} />
        <div>时</div>
      </div>
      <div className="form-row">
        <div className="form-item-text-tips">通过以下渠道开启催收</div>
      </div>
    </div>
  );
}

export default Collection;
